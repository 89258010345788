body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body, textarea, .typo-body {
  font-size: 0.875rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.46429em;
}

#root {
  height: calc(100vh - 80px);
  width: 100%;
}

.with-sidebar {
  display: flex;
  flex-wrap: wrap;
  gap: var(--s1);
}

.with-sidebar > :first-child {
  flex-grow: 1;
}

.with-sidebar > :last-child {
  flex-basis: 0;
  flex-grow: 999;
  min-inline-size: 50%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.question-container p {
  display: inline;
}

.typo-h3 {
  font-size: 3rem;
}

.typo-h4 {
  font-size: 2.125rem;
}

.typo-h5 {
  font-size: 1.5rem;
}

.typo-h6 {
  font-size: 1.2rem;
}

.typo-bold {
  font-weight: 700;
}

// Utilities
.u-alignItems-center { align-items: center }
.u-alignItems-flexEnd { align-items: flex-end }
.u-alignItems-flexStart { align-items: flex-start }
.u-alignItems-stretch { align-items: stretch }
.u-alignSelf-flexEnd { align-self: flex-end }
.u-alignSelf-flexStart { align-self: flex-start }
.u-alignSelf-center { align-self: center }
.u-background-primary { background: #b71c1c !important }
.u-boxShadow-topLeft { box-shadow: -2px -2px 4px 0px #21212130 }
.u-boxShadow-none { box-shadow: 0 0 0 0 !important }
.u-color-inherit { color: inherit }
.u-color-white { color: #ffffff }
.u-color-red { color: #ff1744 !important }
.u-content { content: '' }
.u-cursor-pointer { cursor: pointer }
.u-display-flex { display: flex }
.u-display-grid { display: grid }
.u-display-inline { display: inline }
.u-display-block { display: block }
.u-display-inlineBlock { display: inline-block }
.u-display-inlineFlex { display: inline-flex }
.u-display-none { display: none !important }
.u-flex-auto { flex: auto }
.u-flex-none { flex: none }
.u-flex-1 { flex: 1 }
.u-flexBasis-0 { flex-basis: 0 }
.u-flexBasis-auto { flex-basis: auto }
.u-flexDirection-column { flex-direction: column }
.u-flexDirection-rowReverse { flex-direction: row-reverse }
.u-flexGrow-1 { flex-grow: 1 }
.u-flexShrink-0 { flex-shrink: 0 }
.u-flexWrap-wrap { flex-wrap: wrap }
.u-float-left { float: left }
.u-float-right { float: right }
.u-fontWeight-300 { font-weight: 300 }
.u-fontWeight-500 { font-weight: 500 }
.u-fontWeight-700 { font-weight: 700 }
.u-grid-auto-columns-grow { grid-auto-columns: minmax(0, 1fr) }
.u-grid-auto-rows-grow { grid-auto-rows: minmax(0, 1fr) }
.u-grid-auto-flow-column { grid-auto-flow: column }
.u-grid-auto-flow-row { grid-auto-flow: row }
.u-height-100 { height: 100% }
.u-height-50 { height: 50% }
.u-height-0 { height: 0 }
.u-height-fitContent { height: fit-content }
.u-justifyContent-flexEnd { justify-content: flex-end }
.u-justifyContent-flexStart { justify-content: flex-start }
.u-justifyContent-center { justify-content: center }
.u-justifyContent-spaceBetween { justify-content: space-between }
.u-justifyContent-spaceEvenly { justify-content: space-evenly }
.u-lineHeight-1 { line-height: 1 }
.u-lineHeight-15 { line-height: 1.5 }
.u-margin-0Auto { margin: 0 auto }
.u-margin-bottom-clearChat { margin-bottom: 120px }
.u-margin-top-auto { margin-top: auto }
.u-margin-right-auto { margin-right: auto }
.u-margin-left-auto { margin-left: auto }
.u-padding-bottom-clearChat { padding-bottom: 120px }
.u-overflow-auto { overflow: auto }
.u-overflow-hidden { overflow: hidden }
.u-overflowWrap-breakWord { overflow-wrap: break-word }
.u-overflowX-auto { overflow-x: auto }
.u-overflowX-scroll { overflow-x: scroll }
.u-overflowY-auto { overflow-y: auto }
.u-overflowY-scroll { overflow-y: scroll }
.u-position-relative { position: relative }
.u-position-absolute { position: absolute }
.u-position-sticky {  position: sticky }
.u-position-top-sticky { position: sticky; top: 0; z-index: 9999 }
.u-scaleDown { object-fit: scale-down }
.u-textAlign-center { text-align: center }
.u-textAlign-initial { text-align: initial }
.u-textAlign-left { text-align: left }
.u-textAlign-right { text-align: right }
.u-textDecoration-underline { text-decoration: underline }
.u-textDecoration-lineThrough { text-decoration: line-through }
.u-verticalAlign-middle { vertical-align: middle }
.u-width-100 { width: 100% }
.u-width-50 { width: 50% }
.u-whiteSpace-pre { white-space: pre }
.u-whiteSpace-preWrap { white-space: pre-wrap }
.u-whiteSpace-nowrap { white-space: nowrap }
.u-wordBreak-breakAll { word-break: break-all }
.u-wordBreak-breakWord { word-break: break-word }

.u-red-border {
  border: #ff1744;
  border-style: solid;
  border-width: 1px;
}

.u-flexbox-center {
  display: flex;
  align-items: center;
}

.u-spread {
  @extend .u-flexbox-center;
  justify-content: space-between;
}

.u-flexColumn {
  display: flex;
  flex-direction: column;
}

.u-hover-highlight {
  &:hover {
    background-color: rgba(0, 0 , 0 ,0.04);
  }
}

// Pixel width and height utilities 50 to 1000 in increments of 25px (including min's and max's)
// u-width-25px, u-minWidth-25px, u-maxWidth-25px, u-minHeight-25px, .u-maxHeight-25px, u-gap-25px
// Also adds 30px width and height that are used for the calendar, but we think may have use in other places
.u-width-30px { width: 30px }
.u-height-30px { height: 30px }
$i: 25;
@while $i <= 1000 {
  .u-width-#{$i}px { width: #{$i}px }
  .u-height-#{$i}px { height: #{$i}px }
  .u-minWidth-#{$i}px { min-width: #{$i}px }
  .u-maxWidth-#{$i}px { max-width: #{$i}px }
  .u-minHeight-#{$i}px { min-height: #{$i}px }
  .u-maxHeight-#{$i}px { max-height: #{$i}px }
  .u-gap-#{$i}px { gap: #{$i}px }
  $i: $i + 25;
}

// Margin and padding utility classes. This loop creates a handful of useful spacing
// classes. Sizes are xs(3px), sm(5px), md(10px), lg(15px), xl(20px), 2x(30px).
// Example usage:
//  .u-margin - 10px margin on all sides
//  .u-margin-top-xl - 20px margin on top
//  .u-no-margin - no margin
//  .u-padding-xs - 3px padding on all sides
//  .u-padding-x - 10px margin on left and right
//  .u-margin-y-xl - 20px margin on top and bottom

$gutter-px: 10px;
$gutter-keywords: margin padding;
$gutter-directions: top right bottom left;
$gutter-sizes: (
  xs: 0.3,
  sm: 0.5,
  md: 1,
  lg: 1.5,
  xl: 2,
  2x: 3
);

$gutter-keywords: margin padding;
$gutter-directions: top right bottom left;

@each $keyword in $gutter-keywords {
  .u-#{$keyword} { #{$keyword}: 10px !important; }
  .u-#{$keyword}-x { #{$keyword}-left: 10px !important; #{$keyword}-right: 10px !important; }
  .u-#{$keyword}-y { #{$keyword}-top: 10px !important; #{$keyword}-bottom: 10px !important; }
  .u-no-#{$keyword} { #{$keyword}: 0 !important; }

  @each $size, $multiplier in $gutter-sizes {
    .u-#{$keyword}-#{$size} { #{$keyword}: 10px * $multiplier !important; }
    .u-#{$keyword}-x-#{$size} { #{$keyword}-left: 10px * $multiplier !important; #{$keyword}-right: 10px * $multiplier !important; }
    .u-#{$keyword}-y-#{$size} { #{$keyword}-top: 10px * $multiplier !important; #{$keyword}-bottom: 10px * $multiplier !important; }
  }

  @each $direction in $gutter-directions {
    .u-#{$keyword}-#{$direction} { #{$keyword}-#{$direction}: 10px !important; }
    .u-no-#{$keyword}-#{$direction} { #{$keyword}-#{$direction}: 0 !important; }

    @each $size, $multiplier in $gutter-sizes {
      .u-#{$keyword}-#{$direction}-#{$size} { #{$keyword}-#{$direction}: 10px * $multiplier !important; }
    }
  }
}
