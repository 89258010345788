.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Resets (http://meyerweb.com/eric/tools/css/reset/ | v2.0 | 20110126 | License: none (public domain)) */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  -webkit-text-size-adjust: none;
}

/* Box Model */

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* Container */

.container {
  margin-left: auto;
  margin-right: auto;

  /* width: (containers) */
  width: 51em;
}

/* Modifiers */

/* 125% */
.container.\31 25\25 {
  width: 100%;

  /* max-width: (containers * 1.25) */
  max-width: 63.75em;

  /* min-width: (containers) */
  min-width: 51em;
}

/* 75% */
.container.\37 5\25 {
  /* width: (containers * 0.75) */
  width: 38.25em;
}

/* 50% */
.container.\35 0\25 {
  /* width: (containers * 0.50) */
  width: 25.5em;
}

/* 25% */
.container.\32 5\25 {
  /* width: (containers * 0.25) */
  width: 12.75em;
}

/* Grid */

.row {
  border-bottom: solid 1px transparent;
}

.row > * {
  float: left;
}

.row:after,
.row:before {
  content: '';
  display: block;
  clear: both;
  height: 0;
}

.row.uniform > * > :first-child {
  margin-top: 0;
}

.row.uniform > * > :last-child {
  margin-bottom: 0;
}

/* Gutters */

/* Normal */

.row > * {
  /* padding: (gutters.horizontal) 0 0 (gutters.vertical) */
  padding: 30px 0 0 30px;
}

.row {
  /* margin: -(gutters.horizontal) 0 -1px -(gutters.vertical) */
  margin: -30px 0 -1px -30px;
}

.row.uniform > * {
  /* padding: (gutters.vertical) 0 0 (gutters.vertical) */
  padding: 30px 0 0 30px;
}

.row.uniform {
  /* margin: -(gutters.vertical) 0 -1px -(gutters.vertical) */
  margin: -30px 0 -1px -30px;
}

/* 200% */

.row.\32 00\25 > * {
  /* padding: (gutters.horizontal) 0 0 (gutters.vertical) */
  padding: 60px 0 0 60px;
}

.row.\32 00\25 {
  /* margin: -(gutters.horizontal) 0 -1px -(gutters.vertical) */
  margin: -60px 0 -1px -60px;
}

.row.uniform.\32 00\25 > * {
  /* padding: (gutters.vertical) 0 0 (gutters.vertical) */
  padding: 60px 0 0 60px;
}

.row.uniform.\32 00\25 {
  /* margin: -(gutters.vertical) 0 -1px -(gutters.vertical) */
  margin: -60px 0 -1px -60px;
}

/* 150% */

.row.\31 50\25 > * {
  /* padding: (gutters.horizontal) 0 0 (gutters.vertical) */
  padding: 45px 0 0 45px;
}

.row.\31 50\25 {
  /* margin: -(gutters.horizontal) 0 -1px -(gutters.vertical) */
  margin: -45px 0 -1px -45px;
}

.row.uniform.\31 50\25 > * {
  /* padding: (gutters.vertical) 0 0 (gutters.vertical) */
  padding: 45px 0 0 45px;
}

.row.uniform.\31 50\25 {
  /* margin: -(gutters.vertical) 0 -1px -(gutters.vertical) */
  margin: -45px 0 -1px -45px;
}

/* 50% */

.row.\35 0\25 > * {
  /* padding: (gutters.horizontal) 0 0 (gutters.vertical) */
  padding: 15px 0 0 15px;
}

.row.\35 0\25 {
  /* margin: -(gutters.horizontal) 0 -1px -(gutters.vertical) */
  margin: -15px 0 -1px -15px;
}

.row.uniform.\35 0\25 > * {
  /* padding: (gutters.vertical) 0 0 (gutters.vertical) */
  padding: 15px 0 0 15px;
}

.row.uniform.\35 0\25 {
  /* margin: -(gutters.vertical) 0 -1px -(gutters.vertical) */
  margin: -15px 0 -1px -15px;
}

/* 25% */

.row.\32 5\25 > * {
  /* padding: (gutters.horizontal) 0 0 (gutters.vertical) */
  padding: 7.5px 0 0 7.5px;
}

.row.\32 5\25 {
  /* margin: -(gutters.horizontal) 0 -1px -(gutters.vertical) */
  margin: -7.5px 0 -1px -7.5px;
}

.row.uniform.\32 5\25 > * {
  /* padding: (gutters.vertical) 0 0 (gutters.vertical) */
  padding: 7.5px 0 0 7.5px;
}

.row.uniform.\32 5\25 {
  /* margin: -(gutters.vertical) 0 -1px -(gutters.vertical) */
  margin: -7.5px 0 -1px -7.5px;
}

/* 0% */

.row.\30 \25 > * {
  padding: 0;
}

.row.\30 \25 {
  margin: 0 0 -1px 0;
}

/* Cells */

.\31 2u,
.\31 2u\24,
.\31 2u\28 1\29,
.\31 2u\24\28 1\29 {
  width: 100%;
  clear: none;
}
.\31 1u,
.\31 1u\24,
.\31 1u\28 1\29,
.\31 1u\24\28 1\29 {
  width: 91.6666666667%;
  clear: none;
}
.\31 0u,
.\31 0u\24,
.\31 0u\28 1\29,
.\31 0u\24\28 1\29 {
  width: 83.3333333333%;
  clear: none;
}
.\39 u,
.\39 u\24,
.\39 u\28 1\29,
.\39 u\24\28 1\29 {
  width: 75%;
  clear: none;
}
.\38 u,
.\38 u\24,
.\38 u\28 1\29,
.\38 u\24\28 1\29 {
  width: 66.6666666667%;
  clear: none;
}
.\37 u,
.\37 u\24,
.\37 u\28 1\29,
.\37 u\24\28 1\29 {
  width: 58.3333333333%;
  clear: none;
}
.\36 u,
.\36 u\24,
.\36 u\28 1\29,
.\36 u\24\28 1\29 {
  width: 50%;
  clear: none;
}
.\35 u,
.\35 u\24,
.\35 u\28 1\29,
.\35 u\24\28 1\29 {
  width: 41.6666666667%;
  clear: none;
}
.\34 u,
.\34 u\24,
.\34 u\28 1\29,
.\34 u\24\28 1\29 {
  width: 33.3333333333%;
  clear: none;
}
.\33 u,
.\33 u\24,
.\33 u\28 1\29,
.\33 u\24\28 1\29 {
  width: 25%;
  clear: none;
}
.\32 u,
.\32 u\24,
.\32 u\28 1\29,
.\32 u\24\28 1\29 {
  width: 16.6666666667%;
  clear: none;
}
.\31 u,
.\31 u\24,
.\31 u\28 1\29,
.\31 u\24\28 1\29 {
  width: 8.3333333333%;
  clear: none;
}

.\31 2u\24 + *,
.\31 2u\24\28 1\29 + *,
.\31 1u\24 + *,
.\31 1u\24\28 1\29 + *,
.\31 0u\24 + *,
.\31 0u\24\28 1\29 + *,
.\39 u\24 + *,
.\39 u\24\28 1\29 + *,
.\38 u\24 + *,
.\38 u\24\28 1\29 + *,
.\37 u\24 + *,
.\37 u\24\28 1\29 + *,
.\36 u\24 + *,
.\36 u\24\28 1\29 + *,
.\35 u\24 + *,
.\35 u\24\28 1\29 + *,
.\34 u\24 + *,
.\34 u\24\28 1\29 + *,
.\33 u\24 + *,
.\33 u\24\28 1\29 + *,
.\32 u\24 + *,
.\32 u\24\28 1\29 + *,
.\31 u\24 + *,
.\31 u\24\28 1\29 + * {
  clear: left;
}

.\-11u {
  margin-left: 91.6666666667%;
}
.\-10u {
  margin-left: 83.3333333333%;
}
.\-9u {
  margin-left: 75%;
}
.\-8u {
  margin-left: 66.6666666667%;
}
.\-7u {
  margin-left: 58.3333333333%;
}
.\-6u {
  margin-left: 50%;
}
.\-5u {
  margin-left: 41.6666666667%;
}
.\-4u {
  margin-left: 33.3333333333%;
}
.\-3u {
  margin-left: 25%;
}
.\-2u {
  margin-left: 16.6666666667%;
}
.\-1u {
  margin-left: 8.3333333333%;
}

/*
	Directive by HTML5 UP
	html5up.net | @n33co
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Basic */

html,
body {
  height: 100%;
}

body {
  background: white;
}

body.is-loading * {
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
  -moz-animation: none !important;
  -webkit-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

body,
input,
select,
textarea {
  color: #6e6e6e;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16pt;
  font-weight: 400;
  line-height: 1.75em;
}

a {
  -moz-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out,
    border-bottom-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
  border-bottom: dotted 1px;
  color: rgb(131, 23, 27);
  text-decoration: none;
}

a:hover {
  border-bottom-color: transparent;
}

strong,
b {
  font-weight: 600;
  color: #5b5b5b;
}

em,
i {
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #5b5b5b;
  font-weight: 700;
  letter-spacing: 0.125em;
  line-height: 1.75em;
  margin-bottom: 1em;
  text-transform: uppercase;
  text-align: center;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  text-decoration: none;
}

h2 {
  font-size: 1.75em;
}

h3 {
  font-size: 1.5em;
}

sub {
  font-size: 0.8em;
  position: relative;
  top: 0.5em;
}

sup {
  font-size: 0.8em;
  position: relative;
  top: -0.5em;
}

hr {
  border: 0;
  border-top: solid 1px rgba(224, 224, 224, 0.75);
  margin-bottom: 1.5em;
}

blockquote {
  border-left: solid 0.5em rgba(224, 224, 224, 0.75);
  font-style: italic;
  padding: 1em 0 1em 2em;
}

/* Section/Article */

section.special,
article.special {
  text-align: center;
}

header {
  text-align: center;
}

header p {
  position: relative;
  top: -1em;
  color: #888888;
  text-transform: uppercase;
  letter-spacing: 0.075em;
}

header.major {
  border-bottom-width: 1px;
  border-color: rgba(224, 224, 224, 0.75);
  border-style: solid;
  border-top-width: 1px;
  margin-bottom: 6em;
  padding: 4em 0;
  position: relative;
}

header.major:before,
header.major:after {
  background: rgba(224, 224, 224, 0.75);
  content: '';
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}

header.major:before {
  top: 10px;
}

header.major:after {
  bottom: 10px;
}

header.major h2 {
  margin: 0;
}

header.major p {
  top: 0;
  margin: 1.5em 0 0 0;
  padding: 1.5em 1.5em 0 1.5em;
  font-size: 1.5em;
  line-height: 1.75em;
  border-top: solid 1px rgba(224, 224, 224, 0.75);
  display: inline-block;
}

header.major.last {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

header.major.last:after {
  display: none;
}

header.major.last h2 {
  margin-bottom: 1em;
}

footer {
  text-align: center;
}

footer.major {
  border-bottom-width: 1px;
  border-color: rgba(224, 224, 224, 0.75);
  border-style: solid;
  border-top-width: 1px;
  margin-top: 6em;
  padding: 4em 0;
  position: relative;
}

footer.major:before,
footer.major:after {
  background: rgba(224, 224, 224, 0.75);
  content: '';
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}

footer.major:before {
  top: 10px;
}

footer.major:after {
  bottom: 10px;
}

/* Form */

label {
  display: block;
  color: #5b5b5b;
  font-size: 0.8em;
  font-weight: 700;
  letter-spacing: 0.125em;
  line-height: 1.75em;
  text-transform: uppercase;
  margin: 0 0 0.5em 0;
}

input[type='text'],
input[type='password'],
input[type='email'],
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  background: none;
  border-radius: 4px;
  border: 0;
  border: solid 1px rgba(224, 224, 224, 0.75);
  color: inherit;
  display: block;
  outline: 0;
  padding: 0.75em;
  text-decoration: none;
  width: 100%;
  opacity: 1;
}

input[type='text']:focus,
input[type='password']:focus,
input[type='email']:focus,
select:focus,
textarea:focus {
  background: rgba(224, 224, 224, 0.15);
  border-color: rgb(131, 23, 27);
}

input[type='text'],
input[type='password'],
input[type='email'],
select {
  line-height: 1em;
}

textarea {
  padding: 0.5em 0.75em;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.5;
  position: relative;
  top: 3px;
}

:-moz-placeholder {
  color: inherit;
  opacity: 0.5;
}

::-moz-placeholder {
  color: inherit;
  opacity: 0.5;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 0.5;
}

.formerize-placeholder {
  color: rgba(110, 110, 110, 0.5) !important;
}

/* Image */

.image {
  border: 0;
  display: inline-block;
  position: relative;
}

.image:after {
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out;
  -ms-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
  background-image: url('images/overlay.png');
  content: '';
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.2;
  position: absolute;
  top: 0;
  width: 100%;
}

.image img {
  display: block;
}

.image.fit {
  display: block;
}

.image.fit img {
  display: block;
  width: 100%;
}

.image.feature {
  display: block;
  margin: 0 0 2em 0;
}

.image.feature img {
  display: block;
  width: 100%;
}

.image.icon {
  -moz-perspective: 500px;
  -webkit-perspective: 500px;
  -o-perspective: 500px;
  -ms-perspective: 500px;
  perspective: 500px;
}

.image.icon:before {
  -moz-transition: transform 0.1s ease-in-out, font-size 0.1s ease-in-out;
  -webkit-transition: transform 0.1s ease-in-out, font-size 0.1s ease-in-out;
  -o-transition: transform 0.1s ease-in-out, font-size 0.1s ease-in-out;
  -ms-transition: transform 0.1s ease-in-out, font-size 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, font-size 0.1s ease-in-out;
  color: #fff;
  font-size: 6em;
  height: 1em;
  left: 50%;
  line-height: 1em;
  margin: -0.5em 0 0 -0.5em;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 1em;
  z-index: 1;
}

.image.icon:hover:before {
  -moz-transform: translateZ(1em);
  -webkit-transform: translateZ(1em);
  -o-transform: translateZ(1em);
  -ms-transform: translateZ(1em);
  transform: translateZ(1em);
}

.image.icon:hover:after {
  opacity: 1;
}

span.image.icon {
  cursor: default;
}

/* Icon */

.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;
}

.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

.icon > .label {
  display: none;
}

/* List */

ol.default {
  list-style: decimal;
  padding-left: 1.25em;
}

ol.default li {
  padding-left: 0.25em;
}

ul.default {
  list-style: disc;
  padding-left: 1em;
}

ul.default li {
  padding-left: 0.5em;
}

ul.icons {
  cursor: default;
}

ul.icons li {
  display: inline-block;
  font-size: 1.25em;
  line-height: 1em;
  padding-left: 0.5em;
}

ul.icons li:first-child {
  padding-left: 0;
}

ul.icons li a,
ul.icons li span {
  -moz-transition: background-color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  border-radius: 100%;
  border: solid 1px rgba(224, 224, 224, 0.75);
  display: inline-block;
  height: 2em;
  line-height: 2em;
  text-align: center;
  width: 2em;
}

ul.icons li a:hover,
ul.icons li span:hover {
  background: rgba(224, 224, 224, 0.15);
}

ul.actions {
  cursor: default;
  text-align: center;
}

ul.actions:last-child {
  margin-bottom: 0;
}

ul.actions li {
  display: inline-block;
  padding: 0 0 0 1.5em;
}

ul.actions li:first-child {
  padding: 0;
}

ul.actions.vertical li {
  display: block;
  padding: 1.5em 0 0 0;
}

ul.actions.vertical li:first-child {
  padding: 0;
}

/* Table */

.table-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

table {
  width: 100%;
}

table.default {
  width: 100%;
}

table.default tbody tr {
  border-bottom: solid 1px rgba(224, 224, 224, 0.75);
}

table.default tbody tr:nth-child(2n - 1) {
  background: rgba(224, 224, 224, 0.15);
}

table.default td {
  padding: 0.5em 1em 0.5em 1em;
}

table.default th {
  color: #5b5b5b;
  font-size: 0.8em;
  font-weight: 700;
  letter-spacing: 0.125em;
  line-height: 1.75em;
  padding: 0.5em 1em 0.5em 1em;
  text-align: left;
  text-transform: uppercase;
}

table.default thead {
  border-bottom: solid 1px rgba(224, 224, 224, 0.75);
}

/* Button */

input[type='submit'],
input[type='reset'],
input[type='button'],
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  background: #544d55;
  border-radius: 4px;
  border: 0;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 0.9em;
  font-weight: 700;
  letter-spacing: 0.125em;
  line-height: 3.25em;
  min-width: 15em;
  padding: 0 1.5em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

input[type='submit']:hover,
input[type='reset']:hover,
input[type='button']:hover,
.button:hover {
  background: #6e6570;
}

input[type='submit']:active,
input[type='reset']:active,
input[type='button']:active,
.button:active {
  background: #474148;
}

input[type='submit'].alt,
input[type='reset'].alt,
input[type='button'].alt,
.button.alt {
  background: none;
  box-shadow: inset 0 0 0 1px rgba(224, 224, 224, 0.75);
  color: #544d55;
}

input[type='submit'].alt:hover,
input[type='reset'].alt:hover,
input[type='button'].alt:hover,
.button.alt:hover {
  background: rgba(224, 224, 224, 0.15);
}

input[type='submit'].alt:active,
input[type='reset'].alt:active,
input[type='button'].alt:active,
.button.alt:active {
  background: rgba(224, 224, 224, 0.35);
}

input[type='submit'].fit,
input[type='reset'].fit,
input[type='button'].fit,
.button.fit {
  width: 100%;
}

input[type='submit'].small,
input[type='reset'].small,
input[type='button'].small,
.button.small {
  font-size: 0.8em;
}

/* Box */

.box {
  background: white;
  border: solid 1px rgba(224, 224, 224, 0.75);
  margin-bottom: 6em;
  padding: 3.5em;
}

.box.alt {
  background: none;
  border: none;
  padding: 0;
}

/* Feature */

.feature {
  position: relative;
  height: 20em;
}

.feature:after {
  clear: both;
  content: '';
  display: block;
}

.feature h3,
.feature h4,
.feature h5,
.feature h6 {
  text-align: inherit;
}

.feature .image {
  display: inline-block;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 50%;
}

.feature .image img {
  display: block;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
}

.feature:before {
  background: blue;
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.feature .content {
  display: inline-block;
  padding: 3.5em;
  vertical-align: middle;
  width: 48%;
}

.feature .content h3 {
  margin-bottom: 0.5em;
}

.feature .content :last-child {
  margin: 0;
}

.feature.left {
  text-align: left;
}

.feature.left .image {
  float: left;
}

.feature.left .image img {
  right: 0;
  top: 0;
}

.feature.left .content {
  padding-right: 0;
}

.feature.right {
  text-align: right;
}

.feature.right .image {
  float: right;
}

.feature.right .image img {
  left: 0;
  top: 0;
}

.feature.right .content {
  padding-left: 0;
}

.feature.long .content {
  padding-top: 0;
}

/* Header */

#header {
  background-attachment: scroll, scroll, fixed;
  background-image: url('images/top-3200.svg'), url('images/overlay.png'),
    url('images/header.jpg');
  background-position: bottom center, top left, center center;
  background-repeat: repeat-x, repeat, no-repeat;
  background-size: 3200px 460px, auto, cover;
  color: rgba(255, 255, 255, 0.8);
  padding: 10em 0 20em 0;
  position: relative;
  text-align: center;
}

#header:before,
#header:after {
  background: rgba(224, 224, 224, 0.75);
  content: '';
  left: 50%;
  position: absolute;
  width: 1px;
  z-index: 0;
}

#header:before {
  height: calc(100% - 50px);
  opacity: 0.15;
  top: 0;
}

#header:after {
  bottom: 0;
  height: 50px;
}

#header a {
  border-bottom-color: rgba(255, 255, 255, 0.35);
  color: rgba(255, 255, 255, 0.8);
}

#header a:hover {
  border-bottom-color: transparent;
  color: rgb(131, 23, 27);
}

#header strong,
#header b {
  color: white;
}

#header h1,
#header h2,
#header h3,
#header h4,
#header h5,
#header h6 {
  color: white;
}

#header input[type='submit'],
#header input[type='reset'],
#header input[type='button'],
#header .button {
  background: white;
  color: #544d55;
}

#header .logo {
  background: rgb(131, 23, 27);
  border-radius: 100%;
  color: white;
  cursor: default;
  display: inline-block;
  font-size: 2.25em;
  height: 2.35em;
  line-height: 2.35em;
  margin: 0 0 0.75em 0;
  text-align: center;
  width: 2.35em;
}

#header .logo.fa-paper-plane-o:before {
  left: -0.075em;
  position: relative;
}

#header h1 {
  font-size: 2.5em;
  margin: 0;
}

#header p {
  font-size: 1.5em;
  line-height: 1.5em;
  margin: 0.25em 0 0 0;
}

/* Footer */

#footer {
  background-attachment: scroll;
  background-color: rgb(131, 23, 27);
  background-image: url('images/bottom-3200.svg');
  background-position: top center;
  background-repeat: repeat-x;
  background-size: 3200px 460px;
  color: rgba(255, 255, 255, 0.8);
  /*
		Since the SVG background occupies a lot of vertical space, we'll
		add additional top padding (to move our content out of its way)
		and a negative margin (to move everything up a bit).
		*/
  margin-top: -260px;
  padding: calc(460px + 6em) 0 10em 0;
  position: relative;
  text-align: center;
}

#footer:before {
  background: rgba(224, 224, 224, 0.75);
  content: '';
  height: calc(460px + 6em);
  left: 50%;
  position: absolute;
  top: 0;
  width: 1px;
  z-index: 0;
}

#footer a {
  color: white;
}

#footer strong,
#footer b {
  color: white;
}

#footer h1,
#footer h2,
#footer h3,
#footer h4,
#footer h5,
#footer h6 {
  color: white;
}

#footer header p {
  color: rgba(255, 255, 255, 0.5);
}

#footer input[type='submit'],
#footer input[type='reset'],
#footer input[type='button'],
#footer .button {
  background: white;
  box-shadow: inset 0 0 0 1px white;
  color: rgb(131, 23, 27);
}

#footer input[type='submit']:hover,
#footer input[type='reset']:hover,
#footer input[type='button']:hover,
#footer .button:hover {
  background: rgba(224, 224, 224, 0.15);
  color: white;
}

#footer input[type='text']:focus,
#footer input[type='password']:focus,
#footer input[type='email']:focus,
#footer select:focus,
#footer textarea:focus {
  border-color: white;
}

#footer .formerize-placeholder {
  color: rgba(255, 255, 255, 0.3) !important;
}

#footer form {
  margin: 3em 0;
}

#footer .copyright {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.8em;
  line-height: 1em;
  margin: 3em 0 0 0;
}

#footer .copyright li {
  border-left: dotted 1px;
  display: inline-block;
  margin-left: 1em;
  padding-left: 1em;
}

#footer .copyright li:first-child {
  padding: 0;
  margin: 0;
  border: 0;
}

#footer .copyright li a,
#footer .copyright li span {
  color: inherit;
}

#footer .copyright li a:hover,
#footer .copyright li span:hover {
  color: white;
}

/* Main */

#main {
  padding: 6em 0 5em 0;
  position: relative;
  z-index: 1;
}

#main:before {
  background: rgba(224, 224, 224, 0.75);
  content: '';
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  width: 1px;
  z-index: -1;
}

#main header.major,
#main footer.major {
  background: white;
}

/*
	Directive by HTML5 UP
	html5up.net | @n33co
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Basic */

body,
input,
select,
textarea {
  font-size: 14pt;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.25em;
}

/* Section/Article */

header.major p {
  font-size: 1.25em;
}

/* Header */

#header {
  padding: 8em 0 16em 0;
}

#header h1 {
  font-size: 2.25em;
}

#header p {
  font-size: 1.25em;
  line-height: 1.65em;
}
